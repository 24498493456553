export interface Repairer {
  name: string;
  debtor_number: number;
  address: string;
  postcode: string;
  city: string;
  brands: null | string;
  latitude: number;
  longitude: number;
}

export interface GoogleMapData {
  repairers: Repairer[];
  inputName: string;
  network?: number;
  glass?: number;
}

export interface Field {
  name: string;
  label: string;
}

export interface SingleDatePickerProps {
  date: string | number | null;
  disabled: boolean;
  hasTimePicker: boolean;
  timeInputName: string;
  inputName: string;
  minDate: string | number | null;
  maxDate: string | number | null;
  onlyWorkdays: boolean;
  showYearDropdown: boolean;
}

export interface DateRangePickerProps {
  date: string | number | null;
  disabled: boolean;
  inputNameStart: string;
  inputNameEnd: string;
  minDate: string | number | null;
  maxDate: string | number | null;
}

export interface SmartphortDevicesProps {
  schadegevalId: number;
  fields: { [key: string]: string }
}

export interface DeviceDetail {
  Id: number;
  Name: string;
}

export type DeviceType = DeviceDetail;
export type DeviceBrand = DeviceDetail;
export type DeviceModel = DeviceDetail;

export interface ClaimItem {
  value: number;
  label: string;
}

export interface SummaryRequest {
  schadegevalId: number;
  showMeta: boolean;
}

export interface FormValues {
  [key: string]: string;
}

export type InputType = 'text' | 'polar' | 'choice' | 'checkbox' | 'form' | 'date';
export type TranslationKey = string;

export interface Question {
  kind: 'question';
  title: TranslationKey;
  answer: string | TranslationKey | TranslationKey[];
  inputType: InputType;
}

export interface FilePreview {
  originalName: string;
  technicalName: string;
  folderType: number;
  uploadType?: string;
}

export interface ClaimItem extends Object {
  id: number,
  datum_en_tijd_aanmaak: Datum;
  soort_claimant: string;
  rol?: string;
  soort_claim?: string;
  status?: string;
  substatus?: string | null;
  special_status?: string | null;
}

export interface KostenpostItem extends Object {
  kostenpost_id: number,
  omschrijving: string;
  bedrag_incl_btw?: number;
  bedrag_excl_btw?: number;
  soort_begunstigde: string;
  te_betalen_bedrag?: number;
  verrekend_eigen_risico?: number;
  offerte_bedrag_incl_btw?: number;
  offerte_bedrag_excl_btw?: number;
  schadepost_id?: number;
  herstelopdracht_id?: number;
  betaalopdracht_id?: number;
}

export interface SchadepostenItem extends Object {
  id?: number;
  schadepost_id?: number;
  claim_id?: number;
  herstelopdracht_id?: number;
  is_herstelbaar?: boolean;
  schadecategorie: string;
  omschrijving?: string;
  dagwaarde?: number;
  status_voor_systeem?: number;
  schadebedrag_conform_systeem?: string;
  schadebedrag_conform_expert?: number;
  toegekende_schadebedrag?: number;
  leeftijd?: string | null;
  afhandelwijze?: string;
  bedrag_bekend?: string;
  eigendomDerden?: boolean;
  aantal_m?: number;
  aantal_m2?: number;
  subcategorie?: string | null;
  merk?: string | null;
  beschadigingExpert?: string | null;
  beschadigingVerzekerde?: string | null;
  aanschafkosten?: string | number;
  ses_suggestion?: any;
  kostenposten?: KostenpostItem[];
}

export interface PersonInvolved extends Object {
  firstName?: string;
  lastName?: string;
  type?: string;
  hoeVerzekerd? :string;
  email?: string;
  mobile?: string;
  street?: string;
  huisnummer?: string;
  postcode?: string;
  plaats?: string;
  houseNumber?: string | null;
  place?: string | null;
  iban?: string
}

export interface PolisbladItem extends Object {
  id?: number;
  base_deductible?: string;
  begin_date?: Datum | null;
  btw_plichtig?: string;
  currency?: string;
  date_and_time_imported?: Datum | null;
  default_policy?: string;
  due_date?: Datum | null;
  embargo?: string | null;
  end_date?: Datum | null;
  extern_id_normal?: string | null;
  extern_id_unique?: string | null;
  extra_coverages?: string | null;
  import_data?: string | null;
  insured_amount?: number;
  number_of_damages?: number;
  original_insured_amount?: number;
  policy_type_nr?: number;
  policy_type_text?: string | null;
  polisblad_id: string | number | null;
}

export interface RestObjects extends Object {
  title?: string;
  [key: string]: any;
}

export interface Section<T> {
  kind: 'section';
  title: TranslationKey;
  children: T[];
}

export interface Datum {
  date: string;
  timezone_type: number;
  timezone: string;
}

export interface KBCRequest extends Object {
  applicationCode: string,
  notificationNumber: string,
  notifier: string,
}

export interface KBCDamageResponse extends Object {
  noGo?: boolean,
  referenceNumber?: string
}

export interface KBCDamagePost extends Object {
  notificationNumber: string,
  applicationCode?: string,
  externalNotificationNumber?: string,
  language?: string,
  notifier?: string,
  company?: string,
  contractInfo?:
    {
      policyNumber?: string,
      policyTypeCode?: string
    },
  solution?: {
    duplicate?: boolean,
    repairNetworkId?: string,
    type?: string,
  },
}

export interface SummaryResponse {
  bonus_malus_hersteld: string | null;
  bonus_malus_impact: string | null;
  contentClaim?: Section<ClaimItem>;
  contentDamagePostKbc?: Section<KBCDamagePost>;
  contentDamageResponseKbc?: Section<KBCDamageResponse>;
  contentDriver?: Section<PolisbladItem>;
  contentFiles?: Section<FilePreview>;
  contentInjured?: Section<PersonInvolved>;
  contentPolisblad?: Section<PolisbladItem>;
  contentQuestions: Question[];
  contentRequestKbc?: Section<KBCRequest>;
  contentSchadeposten?: Section<SchadepostenItem>;
  contentRepairOrder?: Section<any>;
  contentThirdParty?: Section<PersonInvolved>;
  contentWitness?: Section<[]>;
  eavFieldsSchadegeval?: [];
  datum_en_tijd_aanmaak: Datum;
  gebeurtenis: string;
  gedelegeerde_id?: number | null;
  initiator_id?: number | null;
  office_id: number;
  policyTypeName: string;
  polisbestand: string;
  polisblad_id?: number | null;
  schadedatum: Datum;
  schadegeval_id: number;
  schadelocatie?: string;
  schadelocatie_details?: string | null;
  schadetijdstip?: string | null;
  soort_initiator?: string | null;
  status?: string | null;
  status_details?: string | null;
  substatus?: string | null;
  overview_exclusions_question?: string | null;
}

export interface Summary {
  bonus_malus_hersteld: string | null;
  bonus_malus_impact: string | null;
  contentClaim?: Section<ClaimItem>;
  contentDamagePostKbc?: Section<any>;
  contentDamageResponseKbc?: Section<any>;
  contentDriver?: Section<PolisbladItem>;
  contentFiles?: Section<FilePreview>;
  contentInjured?: Section<PersonInvolved>;
  contentPolisblad?: Section<PolisbladItem>;
  contentQuestions: Question[];
  contentRequestKbc?: Section<any>;
  contentSchadeposten?: Section<SchadepostenItem>;
  contentThirdParty?: Section<PersonInvolved>;
  contentCounterpartyAnswers?: Section<[]>;
  contentRepairOrder?: Section<any>;
  contentWitness?: Section<[]>;
  eavFieldsSchadegeval?: [];
  datum_en_tijd_aanmaak: string;
  gebeurtenis: string;
  gedelegeerde_id?: number | null;
  gelezen_door_behandelaar?: boolean;
  gelezen_door_hersteller?: boolean;
  gelezen_door_makelaar?: boolean;
  initiator_id?: number | null;
  office_id: number;
  policyTypeName: string;
  polisbestand?: string | null;
  polisblad_id?: number | null;
  schadedatum: string;
  schadegeval_id: number;
  schadelocatie?: string;
  schadelocatie_details?: string | null;
  schadetijdstip?: string | null;
  soort_initiator?: string | null;
  status?: string | null;
  status_details?: string | null;
  substatus?: string | null;
  overview_exclusions_question?: string | null;
}

export const isDatum = (obj: any): obj is Datum => {
  return obj && typeof obj === "object" && "date" in obj;
};

export const isStringArray = (arr: any): arr is string[] => {
  return Array.isArray(arr) && arr.every((item) => typeof item === "string");
};

export function isSection<T>(val: any): val is Section<any> {
  return (
    typeof val === "object" && val !== null && "kind" in val && "title" in val
  );
}
