import { DateTime } from "luxon";
import { Datum, isDatum, isStringArray }  from "../types";

export const formatDate = (dat: Datum | Date | string | string[], hasTime = false): string => {
  let date = null;

  console.log("Formatting");

  if (isStringArray(dat)) {
    dat = dat[0];
  }

  if (typeof dat === "string") {
    date = parseDate(dat);
  } else if (isDatum(dat)) {
    console.log("Is Datum: " + dat.date);
    date = parseDate(dat.date);
  } else if (dat instanceof Date) {
    date = DateTime.fromJSDate(dat);
  } else {
    console.log("Unsupported type");
  }

  if (date === null || date === undefined) {
    return "";
  }

  if (hasTime) {
    return date.toFormat("dd-MM-yyyy HH:mm:ss");
  }

  return date.toFormat("dd-MM-yyyy");
};

export const parseDate = (datum: string): DateTime | null => {
  let parsedDate = null;

  parsedDate = DateTime.fromISO(datum);

  if (!parsedDate.isValid) {
    parsedDate = DateTime.fromSQL(datum.split(".")[0]);
  }

  if (!parsedDate.isValid) {
    return null;
  }

  return parsedDate;
};
